import { firestoreAction } from 'vuexfire'
import { db, functions } from '@/firebase'

export const merchantModule = {
	state: () => ({
		merchants: [],
		currentMerchant: null,
	}),

	getters: {
		merchants: state => {
			return state.merchants
		},

		merchant: state => id => {
			return state.merchants.find(merchant => merchant.id === id)
		},

		currentMerchant: state => {
			return state.currentMerchant
		},
	},

	actions: {
		bindMerchants: firestoreAction(async ({ bindFirestoreRef }) => {
			let queryRef = db.collection('merchants')
			return bindFirestoreRef('merchants', queryRef)
		}),

		unbindMerchants: firestoreAction(async ({ unbindFirestoreRef }) => {
			return unbindFirestoreRef('merchants')
		}),

		bindCurrentMerchant: firestoreAction(async ({ bindFirestoreRef }, { id }) => {
			let queryRef = db.collection('merchants').doc(id)
			return bindFirestoreRef('currentMerchant', queryRef)
		}),

		unbindCurrentMerchant: firestoreAction(async ({ unbindFirestoreRef }) => {
			return unbindFirestoreRef('currentMerchant')
		}),

		createMerchant: async (store, { merchant }) => {
			const createMerchant = functions.httpsCallable('createMerchant')
			const result = await createMerchant({ merchant })
			return result.data
		},

		updateMerchant: async (store, { merchant }) => {
			const updateMerchant = functions.httpsCallable('updateMerchant')
			const result = await updateMerchant({ merchant })
			return result.data
		},
	},
}
