import { firestoreAction } from 'vuexfire'
import { db, functions } from '@/firebase'

export const printerModule = {
	state: () => ({
		printers: [],
	}),

	getters: {
		printers: state => {
			return state.printers
		},

		printer: state => id => {
			return state.printers.find(printer => printer.id === id)
		},
	},

	actions: {
		bindPrinters: firestoreAction(async ({ bindFirestoreRef }, { merchantId = null } = {}) => {
			let queryRef = db.collection('printers')
			if (merchantId) queryRef = queryRef.where('merchantId', '==', merchantId)
			return bindFirestoreRef('printers', queryRef)
		}),

		unbindPrinters: firestoreAction(async ({ unbindFirestoreRef }) => {
			return unbindFirestoreRef('printers')
		}),

		createPrinter: async (store, { printer }) => {
			const createPrinter = functions.httpsCallable('createPrinter')
			const result = await createPrinter({ printer })
			return result.data
		},

		updatePrinter: async (store, { printer }) => {
			const updatePrinter = functions.httpsCallable('updatePrinter')
			const result = await updatePrinter({ printer })
			return result.data
		},
	},
}
