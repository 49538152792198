import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations } from 'vuexfire'
import { authModule } from './modules/authModule'
import { merchantModule } from './modules/merchantModule'
import { staffModule } from './modules/staffModule'
import { shopModule } from './modules/shopModule'
import { deviceModule } from './modules/deviceModule'
import { printerModule } from './modules/printerModule'
import { areaModule } from './modules/areaModule'
import { catalogModule } from './modules/catalogModule'
import { saleOrderModule } from './modules/saleOrderModule'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		errorMessage: null,
		showLoading: false,
		showError: false,
	},
	getters: {
		errorMessage: state => {
			return state.errorMessage
		},

		showLoading: state => {
			return state.showLoading
		},

		showError: state => {
			return state.showError
		},
	},
	mutations: {
		...vuexfireMutations,

		'nube/SET_LOADING': (state, { status }) => {
			state.showLoading = status
		},

		'nube/SET_ERROR': (state, { errorMessage, status }) => {
			state.errorMessage = errorMessage
			state.showError = status
		},
	},
	actions: {
		setLoading: ({ commit }, { status }) => {
			commit('nube/SET_LOADING', { status })
		},

		setError: ({ commit }, { errorMessage, status }) => {
			commit('nube/SET_ERROR', { errorMessage, status })
		},
	},
	modules: {
		auth: authModule,
		merchant: merchantModule,
		staff: staffModule,
		shop: shopModule,
		device: deviceModule,
		printer: printerModule,
		area: areaModule,
		catalog: catalogModule,
		saleOrder :saleOrderModule,
	},
})
