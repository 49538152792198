import { firestoreAction } from 'vuexfire'
import { db, functions } from '@/firebase'

export const deviceModule = {
	state: () => ({
		devices: [],
	}),

	getters: {
		devices: state => {
			return state.devices
		},

		device: state => id => {
			return state.devices.find(device => device.id === id)
		},
	},

	actions: {
		bindDevices: firestoreAction(async ({ bindFirestoreRef }) => {
			let queryRef = db.collection('devices')
			return bindFirestoreRef('devices', queryRef)
		}),

		unbindDevices: firestoreAction(async ({ unbindFirestoreRef }) => {
			return unbindFirestoreRef('devices')
		}),

		createDevice: async (store, { device }) => {
			const createDevice = functions.httpsCallable('createDevice')
			const result = await createDevice({ device })
			return result.data
		},

		updateDevice: async (store, { device }) => {
			const updateDevice = functions.httpsCallable('updateDevice')
			const result = await updateDevice({ device })
			return result.data
		},
	},
}
