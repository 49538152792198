import { firestoreAction } from 'vuexfire'
import { db, functions } from '@/firebase'

export const areaModule = {
	state: () => ({
		areas: [],
	}),

	getters: {
		areas: state => {
			return state.areas
		},

		area: state => id => {
			return state.areas.find(area => area.id === id)
		},
	},

	actions: {
		bindAreas: firestoreAction(async ({ bindFirestoreRef }, { merchantId = null } = {}) => {
			let queryRef = db.collection('areas')
			if (merchantId) queryRef = queryRef.where('merchantId', '==', merchantId)
			return bindFirestoreRef('areas', queryRef)
		}),

		unbindAreas: firestoreAction(async ({ unbindFirestoreRef }) => {
			return unbindFirestoreRef('areas')
		}),

		createArea: async (store, { area }) => {
			const createArea = functions.httpsCallable('createArea')
			const result = await createArea({ area })
			return result.data
		},

		updateArea: async (store, { area }) => {
			const updateArea = functions.httpsCallable('updateArea')
			const result = await updateArea({ area })
			return result.data
		},
	},
}
